/* 
Menu Types:
"menu-default", "menu-sub-hidden", "menu-hidden"
*/
export const defaultMenuType = 'menu-default';

export const subHiddenBreakpoint = 1440;
export const menuHiddenBreakpoint = 768;
export const defaultLocale = 'en';
export const localeOptions = [
    {id: 'en', name: 'English - LTR', direction: 'ltr'},
    {id: 'es', name: 'Español', direction: 'ltr'},
    {id: 'enrtl', name: 'English - RTL', direction: 'rtl'},
];

export const firebaseConfig = {
    // apiKey: "AIzaSyDyQNneS1dlMBeBhIRApjdImIOrK8lAwEI",
    // authDomain: "cristo-5c74b.firebaseapp.com",
    // databaseURL: "https://cristo-5c74b.firebaseio.com",
    // projectId: "cristo-5c74b",
    // storageBucket: "cristo-5c74b.appspot.com",
    // messagingSenderId: "88157502574",
    // appId: "1:88157502574:web:679aada6269b0160b2fa7f",
    // measurementId: "G-YK8P6PYQDN"
    apiKey: "AIzaSyBUQmrCYLZavDahD-nv7hUpn3Aa_1FJ4a8",
    authDomain: "boreal-doodad-281910.firebaseapp.com",
    databaseURL: "https://boreal-doodad-281910.firebaseio.com",
    projectId: "boreal-doodad-281910",
    storageBucket: "boreal-doodad-281910.appspot.com",
    messagingSenderId: "852122556352",
    appId: "1:852122556352:web:701f6ae1fad725415cda25",
    measurementId: "G-R6FDNQM8K4"
};

export const searchPath = '#';
export const servicePath = 'http://116.203.81.242:4000/api';

export const themeColorStorageKey = '__theme_selected_color';
export const isMultiColorActive = true;
export const defaultColor = 'light.app';
export const isDarkSwitchActive = true;
export const defaultDirection = 'ltr';
export const themeRadiusStorageKey = '__theme_radius';
export const isDemo = false;
export const colors = [
    'bluenavy',
    'blueyale',
    'blueolympic',
    'greenmoss',
    'greenlime',
    'purplemonster',
    'orangecarrot',
    'redruby',
    'yellowgranola',
    'greysteel',
];
